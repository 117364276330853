import { createContext } from "react";
import { action, computed, observable, toJS, runInAction } from "mobx";

import { BookingItemGroup } from "Models/UI/BookingItemGroup";
import { TrainBundle, TrainConnectionItem, TrainDirection } from "Models/TravelSeller/Models/TS_AddonTrain";
import { AddonTrain } from "Models/UI/AddonTrain";
import SelectFieldOption from "Models/SelectFieldOption";

import workflow from "State/WorkflowService";

export class TrainState {
  @observable ready: boolean = false;
  @observable apiErrorMessage: string | null = null;
  @observable showValidation: boolean = false;
  @observable itemErrorMessages: { [trainId: string]: string } = {};
  @observable showSelectionErrorMessages: boolean = false;

  @observable trainStations: SelectFieldOption[];

  @observable _selectedTrain: AddonTrain | null = null;


  @observable direction: TrainDirection = TrainDirection.ROUNDTRIP;
  @observable bundles: TrainBundle[];
  @observable selectedBundle: TrainBundle;
  @observable departingStationCode: string = "";
  @observable departingClassCode: number = 0;
  @observable departingTrain: TrainConnectionItem;
  @observable departingSearchHour: number;
  @observable departingCabin: number;
  @observable departingSeat: number;
  @observable departingArea: number;

  @observable destinationCode: string = "";

  @observable returningStationCode: string = "";
  @observable returningClassCode: number = 0;
  @observable returningTrain: TrainConnectionItem;
  @observable returningSearchHour: number;
  @observable returningCabin: number;
  @observable returningSeat: number;
  @observable returningArea: number;

  @observable cabin: number;
  @observable seat: number;
  @observable area: number;


  @action initializeDestinationFromBooking() {
    if (workflow.booking && workflow.booking.ritDestination != null) {
      if (workflow.booking.ritDestination.code) {
        // If code is available, use it directly
        this.destinationCode = workflow.booking.ritDestination.code;
      } else if (workflow.booking.ritDestination.name) {
        // If only name is available, try to find the code
        this.destinationCode = this.findStationCodeByName(workflow.booking.ritDestination.name);
      }
    }
  }
  findStationCodeByName(stationName: string): string {
    if (!!this.trainStations && this.trainStations.length > 0 && stationName) {
      const station = this.trainStations.find(station => station.label === stationName);
      if (station) {
        return station.value;
      }
    }
    return "";
  }

  @action setTrainStations(stations: SelectFieldOption[]) {
    this.trainStations = stations;

    this.initializeDestinationFromBooking();
  }

  @action setTrain(train: AddonTrain) {
    // this._selectedTrain = Object.assign({}, train);
    this._selectedTrain = train;
    this.direction = train.dir;
    this.bundles = train.bundles;
    this.selectedBundle = train.bundles && (train.bundles.find(bundle => bundle.id === train.trainBundleSelected) || train.bundles[0]);
    this.departingStationCode = train.departingSelection.stationCode || "";
    this.departingClassCode = train.departingSelection.classCode || 0;
    this.departingTrain = train.departingSelection.train || {};
    this.departingCabin = train.departingSelection.cabinCategory || 0;
    this.departingSeat = train.departingSelection.seatCategory || 0;
    this.departingArea = train.departingSelection.area || 0;
    this.departingSearchHour = train.departingSelection.searchHour || 0;

    this.returningStationCode = train.returningSelection.stationCode || "";
    this.returningClassCode = train.returningSelection.classCode || 0;
    this.returningTrain = train.returningSelection.train || {};
    this.returningCabin = train.returningSelection.cabinCategory || 0;
    this.returningSeat = train.returningSelection.seatCategory || 0;
    this.returningArea = train.returningSelection.area || 0;
    this.returningSearchHour = train.returningSelection.searchHour || 0;

    this.cabin = this.departingCabin || this.returningCabin;
    this.seat = this.departingSeat || this.returningSeat;
    this.area = this.departingArea || this.returningArea;
  }

  getTrain(): AddonTrain | null {
    // const train = Object.assign({}, this._selectedTrain);
    const train = this._selectedTrain;
    if (train) {
      train.dir = this.direction;
      train.bundles = this.bundles;
      train.trainBundleSelected = this.selectedBundle ? this.selectedBundle.id : '';
      train.departingSelection.stationCode = this.departingStationCode;
      train.departingSelection.classCode = this.departingClassCode;
      train.departingSelection.train = toJS(this.departingTrain);
      train.departingSelection.cabinCategory = this.departingCabin;
      train.departingSelection.seatCategory = this.departingSeat;
      train.departingSelection.area = this.departingArea;
      train.departingSelection.searchHour = this.departingSearchHour;

      
      train.returningSelection.stationCode = this.departingStationCode;
      train.returningSelection.classCode = this.departingClassCode;
      //train.returningSelection.stationCode = this.returningStationCde;
      //train.returningSelection.classCode = this.returningClassCode;
      train.returningSelection.train = toJS(this.returningTrain);
      train.returningSelection.cabinCategory = this.returningCabin;
      train.returningSelection.seatCategory = this.returningSeat;
      train.returningSelection.area = this.returningArea;
      train.returningSelection.searchHour = this.returningSearchHour;
    }

    return train;
  }

  /**
   * Departing Part
   */

  @computed get departingTrains(): TrainConnectionItem[] {
    if (this.selectedTrain) {
      if (this.selectedTrain.departingTrains) {
        return this.selectedTrain.departingTrains;
      }
    }
    return [];
  }

  @action setDepartingStationCode(value: string) {
    this.departingStationCode = value;

    // important set default values PEP-119

    this.departingSearchHour = 7;
    this.returningSearchHour = 12;

    if (!this.returningStationCode) {
      this.returningStationCode = value;
    }
  }

  @action setDepartingClassCode(value: number) {
    this.departingClassCode = value;
  }

  @action setDepartingTrain(value: TrainConnectionItem) {
    this.departingTrain = value;
  }

  @action setDepartingSearchHour(value: number) {
    this.departingSearchHour = value;
  }

  @computed get departingStationCodeLabel() {
    if (
      this.departingStationCode &&
      !!this.trainStations &&
      this.trainStations.length > 0
    ) {
      const station = this.trainStations.find(
        station => station.value === this.departingStationCode
      );
      if (station) {
        return station.label;
      }
    }
    return "";
  }

  /**
   * Returning Part
   */

  @action setReturingStationCode(value: string) {
    this.returningStationCode = value;
  }

  @action setReturingClassCode(value: number) {
    this.returningClassCode = value;
  }

  @action setReturningTrain(value: TrainConnectionItem) {
    this.returningTrain = value;
  }

  @action setReturningSearchHour(value: number) {
    this.returningSearchHour = value;
  }

  @action setSelectedBundle(value: TrainBundle) {
    this.selectedBundle = value;
  }

  @computed get returningTrains(): TrainConnectionItem[] {
    if (this.selectedTrain) {
      if (this.selectedTrain.returningTrains) {
        return this.selectedTrain.returningTrains;
      }
    }
    return [];
  }

  @computed get returningStationCodeLabel() {
    if (
      this.returningStationCode &&
      this.trainStations &&
      this.trainStations.length > 0
    ) {
      const station = this.trainStations.find(
        station => station.value === this.returningStationCode
      );
      if (station) {
        return station.label;
      }
    }
    return "";
  }

  /**
   * set values for departing and returning
   */

  @action setCabin(value: number) {
    this.cabin = value;
    this.departingCabin = value;
    this.returningCabin = value;
  }

  @action setSeat(value: number) {
    this.seat = value;
    this.departingSeat = value;
    this.returningSeat = value;
  }

  @action setArea(value: number) {
    this.area = value;
    this.departingArea = value;
    this.returningArea = value;
  }

  /**
   * Validation
   */

  @action updateShowValidation(b: boolean) {
    this.showValidation = b;
  }

  @action validate() {
    this.itemErrorMessages = {};
    if (!!this._selectedTrain) {
      this.validateItem(this._selectedTrain);
    }
  }

  @action validateSelectedItems() {
    this.itemErrorMessages = {};
    if (!!this._selectedTrain) {
      if (
        !!this.train && this._selectedTrain.id === this.train.id
      ) {
        this.validateItem(this._selectedTrain);
      }
    } else {
      if (!this.late && !this.group && (!!this.train || !!this.none)) {
        this.showSelectionErrorMessages = true;
      }
    }
  }

  @action validateItem(item: AddonTrain) {
    let error: string = "";
    if (!!item) {
      if (!item.isReadyToBook) {
        error = "error"; //TODO maybe different errors in future
      }
    }
    runInAction(() => {
      this.updateErrorMessages(error, item);
    });
  }

  @action updateErrorMessages(error: string, item: AddonTrain) {
    if (Object.keys(error).length === 0) {
      if (this.itemErrorMessages.hasOwnProperty(item.id)) {
        delete this.itemErrorMessages[item.id];
      }
    } else {
      this.itemErrorMessages[item.id] = error;
    }
  }

  @action removeItemError(item: AddonTrain) {
    if (this.itemErrorMessages.hasOwnProperty(item.id)) {
      delete this.itemErrorMessages[item.id];
    }
  }
  @action removeAllErrors() {
    this.itemErrorMessages = {};
    this.showSelectionErrorMessages = false;
  }

  @computed get hasItemErrors() {
    return Object.keys(this.itemErrorMessages).length > 0;
  }

  @computed get firstErrorId() {
    return Object.keys(this.itemErrorMessages)[0];
  }

  /**
   * Miscellenous
   */

  // @computed getTotalPrice(train: AddonTrain) {
  //   if (!!this._selectedTrain && train.id === this._selectedTrain.id) {
  //     return this._selectedTrain.totalPrice;
  //   }
  //   return 0;
  // }

  @computed get selectedTrain() {
    return this._selectedTrain;
  }

  get departureDate() {
    if (workflow.booking && workflow.booking.travelStart != null) {
      return workflow.booking.travelStart;
    }
    return "";
  }

  get arrivalDate() {
    if (workflow.booking && workflow.booking.travelEnd != null) {
      return workflow.booking.travelEnd;
    }
    return "";
  }

  /**
   * Destination Part
   */

    // Replace the current destination getter with this
  
  @action setDestinationCode(value: string) {
    this.destinationCode = value;
  }
  
  get destination() {
    if (workflow.booking && workflow.booking.ritDestination != null) 
      return workflow.booking.ritDestination.name || "";
    return "";
  }

  @computed get destinationLabel() {
    if (this.destinationCode && !!this.trainStations && this.trainStations.length > 0) {
      const station = this.trainStations.find(station => station.value === this.destinationCode);
      if (station) { return station.label; }
    }
    return "";
  }

  @action updateApiErrorMessage(msg: string | null) {
    this.apiErrorMessage = msg;
  }

  @computed get itemGroup(): BookingItemGroup | null {
    const groups = workflow.stepItems.filter(
      g => g.id === "api-u-no-give-id-for-train-group"
    );
    if (groups.length === 1) {
      return groups[0];
    }
    return null;
  }

  @action readyNow() {
    this.ready = true;
  }

  @computed get selectedItems() {
    return workflow.selectedItems.filter((i: any) => {
      return i instanceof AddonTrain;
    });
  }

  @computed get hasStepItems() {
    return workflow.allStepIds.length > 0;
  }

  @computed get train() {
    return this.getItemById("120") as AddonTrain;
  }

  @computed get none() {
    return this.getItemById("122") as AddonTrain;
  }

  @computed get late() {
    return this.getItemById("train-late") as AddonTrain;
  }

  @computed get group() {
    return this.getItemById("train-group") as AddonTrain;
  }

  @computed get showNoneItem() : boolean {
    return !this.group && !this.late && !!this.none;
  }

  private getItemById(id: string) {
    const g = toJS(this.itemGroup);

    if (!g) {
      return null;
    }

    const items = g.items.filter(i => i.id === id);

    if (items.length === 1) {
      return items[0];
    }

    return null;
  }

  @action setDirection(value: TrainDirection) {
    this.direction = value;
  }

}

export const trainState = createContext(new TrainState());
